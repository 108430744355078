import request from '@/utils/request';
/** 用户注册 */

/** 账号密码注册 */
export function mobileRegister (data) {
  return request({
    url: `/api/auth-server/register/mobileRegister`,
    method: 'post',
    data: data
  });
}

/** pc端微信注册 */
export function webWeChartRegister (data) {
  return request({
    url: `/api/auth-server/register/webWeChartRegister`,
    method: 'post',
    data: data
  });
}

