/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-this */
/* eslint-disable no-use-before-define */
import Model from 'js-model';
import baseEntity from './entity/framework/baseEntity';
import queryEntity from './entity/framework/queryEntity';
/** 配置说明 通过super.parse拿取 参数modelDisposeConfig
 */
export class Entity extends Model {
  parse(data) {
    addBaseEntity(this);
    return super.parse(data);
  }

  dispose(data) {
    addBaseEntity(this);
    const newData = {};

    for (const key in data) {
      if (data[key] !== []) {
        newData[key] = data[key];
      }
    }
    return super.dispose(newData);
  }

  //删除请求参数中的null,""和 undefined
  delNull(data) {
    const newData = { ...data };

    for (const key in newData) {
      if (newData[key] === null || newData[key] === "" || newData[key] === undefined) {
        delete newData[key];
      } else if (typeof data[key] === 'object') {
        if (data[key] instanceof Array) {
          newData[key] = data[key];
        } else {
          newData[key] = this.delNull(data[key]);
        }
      }
    }
    return newData;
  }
  query(data) {
    addBaseEntity(this);
    return getQueryEntity(this, data);
  }
}
function addBaseEntity(that) {
  const baseEntityValue = baseEntity._model.value;

  for (const key in baseEntityValue) {
    that._model.value[key] = baseEntityValue[key];
  }
}
function getQueryEntity(that, data) {
  const newModel = queryEntity;

  newModel._model.value.queryEntity = that;
  return newModel.parse(data);
}
