<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="query">
    <div id="main" class="query-main" :style="{ marginTop: mainTop }">
      <div v-if="showTop" id="top-body" class="query-form-body">
        <el-scrollbar
          class="table-query-scroll-container"
          :style="{ height: isAll || !isShow ? maxHeight : '45px' }"
        >
          <div class="query-top">
            <div
              id="queryTop"
              class="left"
              :style="{ width: 'calc( 100% - ' + butWidth + ' )' }"
            >
              <el-form
                ref="queryForm"
                inline
                :model="queryData"
                label-width="93px"
              >
                <!--搜索条件插槽-->
                <slot name="form" />
              </el-form>
            </div>
            <div id="quyBut" class="right">
              <el-button
                v-if="props.showQueryBut"
                style="
                  font-family: 'webfont';
                  background: #3ea88d;
                  color: white;
                "
                icon="search"
                @click="query"
              >
                搜 索
              </el-button>
              <el-button
                v-if="props.showResetBut"
                style="font-family: 'webfont'"
                icon="refresh"
                @click="reset"
              >
                重 置
              </el-button>
              <template v-if="isShow">
                <el-link
                  v-if="!isAll"
                  class="buttom"
                  type="warning"
                  @click="isAll = true"
                >
                  更 多
                  <i class="el-icon-arrow-down el-icon--right" />
                </el-link>
                <el-link
                  v-else
                  type="warning"
                  class="buttom"
                  @click="isAll = false"
                >
                  收 起
                  <i class="el-icon-arrow-up el-icon--right" />
                </el-link>
              </template>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <!--按钮插槽-->
      <div id="buts">
        <slot name="buts" class="but-list-body" />
      </div>
      <!--表格插槽-->
      <div id="tableBody" style="margin-top: 5px">
        <el-table
          v-loading="queryData.loading"
          :data="queryData.list"
          row-key="id"
          stripe
          border
          :height="queryData.tableHeight"
          element-loading-text="加载中..."
          style="width: 100%; overflow: auto"
          :span-method="spanMethod"
        >
          <el-table-column
            v-if="indexVisible"
            type="index"
            label="序号"
            align="center"
            width="80"
          />
          <slot name="table" />
          <!-- <el-table-column
            v-if="orgVisible"
            label="所属机构"
            prop="orgName"
            align="center"
            min-width="200"
            show-overflow-tooltip
          />
          <table-admin
            label="所属管理员"
            prop="adminUserName"
            align="center"
            min-width="120"
          /> -->
        </el-table>
        <!-- 分页 -->
        <div style="text-align: center">
          <pagination
            v-if="showPagination"
            v-model:page="queryData.page"
            v-model:limit="queryData.pageSize"
            :total="queryData.total"
            @pagination="queryPagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, nextTick, onMounted } from 'vue';
import elementResizeDetectorMaker from 'element-resize-detector';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

//申明emit事件
const emits = defineEmits(['update:modelValue', 'query', 'reset']),
  router = useRouter(),
  store = useStore(),
  //接受父组件传入参数
  props = defineProps({
    modelValue: {
      type: Object,
      default: null
    },
    /** 显示头部 */
    showTop: {
      type: Boolean,
      default: true
    },
    /** 显示分页 */
    showPagination: {
      type: Boolean,
      default: true
    },
    /** 分页位置 left right center */
    paginationAlign: {
      type: String,
      default: 'center'
    },
    /** 显示查询按钮 */
    showQueryBut: {
      type: Boolean,
      default: true
    },
    /** 显示重置按钮 */
    showResetBut: {
      type: Boolean,
      default: true
    },
    mainTop: {
      type: String,
      default: '10px'
    },
    //是否显示排序
    indexVisible: {
      type: Boolean,
      default: true
    },
    //是否显示机构名称
    orgVisible: {
      type: Boolean,
      default: true
    },
    //合并行或合并列
    spanMethod: {
      type: Object,
      default: null
    }
  });

//申明响应式参数
let queryData = reactive(props.modelValue),
  isAll = ref(false),
  isShow = ref(false),
  pageHeight = ref(90), //分页组件高50 上下在留10个像素的距离
  maxHeight = ref('45px'),
  mainTop = ref(props.mainTop),
  butWidth = ref('0px');

//监听函数
watch(
  () => queryData,
  (newValue, oldValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//设置表格高度
// eslint-disable-next-line one-var
const setTableHeight = () => {
  let height = 0;
  //获取查询区域 window.innerHeight

  if (document.getElementById('main')) {
    height = document.getElementById('main').clientHeight;
  }
  if (document.getElementById('top-body')) {
    const topBodyHeight = document.getElementById('top-body').clientHeight;

    height -= topBodyHeight;
  }
  //获取按钮区域 window.innerHeight
  if (document.getElementById('buts')) {
    const butsBodyHeight = document.getElementById('buts').clientHeight;

    height -= butsBodyHeight;
  }

  queryData.tableHeight = height - pageHeight.value - 40;
  if (queryData.tableHeight < 300) {
    queryData.tableHeight = 300;
  }
};
//设置样式
// eslint-disable-next-line one-var
const setStyle = () => {
    if (props.showTop) {
      if (document.getElementById('queryTop')) {
        isShow.value = document.getElementById('queryTop').scrollHeight > 50;
        maxHeight.value =
          document.getElementById('queryTop').scrollHeight + 'px';
      }
      if (document.getElementById('quyBut').clientWidth) {
        butWidth.value = document.getElementById('quyBut').clientWidth + 'px';
      }
    }
    setTableHeight();
  },
  // 监听div高度变化
  tang = () => {
    const erd = elementResizeDetectorMaker();

    erd.listenTo(document.getElementById('buts'), () => {
      nextTick(() => {
        setStyle();
      });
    });
    erd.listenTo(document.getElementById('tableBody'), () => {
      nextTick(() => {
        setStyle();
      });
    });
    if (props.showTop) {
      // 监听div高度变化
      erd.listenTo(document.getElementById('queryTop'), () => {
        nextTick(() => {
          setStyle();
        });
      });
      erd.listenTo(document.getElementById('quyBut'), () => {
        nextTick(() => {
          setStyle();
        });
      });
    }
    // 监听窗口高度变化
    window.onresize = () => {
      return () => {
        setStyle();
      };
    };
  },
  //查询
  query = () => {
    emits('query');
  },
  //页码改变
  queryPagination = (data) => {
    emits('query');
  },
  //重置
  reset = () => {
    queryData.queryEntity = {};
    queryData.page = 1;
    queryData.dateTimeRange = null;
    queryData.pageSize = 10;
    queryData.total = 0;
    queryData.adminCode = null;
    queryData.orgCode = null;
    emits('reset');
  };

computed(() => {
  // 监听div高度变化
  return tang();
});
onMounted(() => {
  tang();
});
//监听头部菜单实收展开
watch(
  () => store.getters.collapse,
  (newValue) => {
    if (newValue) {
      //展开
      pageHeight.value = 90;
    } else {
      //收缩
      pageHeight.value = 88;
    }
    setTableHeight();
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
<style lang="scss">
.table-query-scroll-container {
  .el-scrollbar__wrap {
    min-height: 105px;
    max-height: 175px;
    overflow-x: hidden !important;
  }
}
</style>
<style lang="scss" scoped>
.query {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  //标题
  &-title {
    position: relative;
    text-align: left;
    font-size: 14px;
    color: #0ef6e8;
    &-text {
      position: relative;
      top: 18px;
      left: 20px;
      font-weight: bold;
    }
  }
  //主查询区域
  &-main {
    position: relative;
    left: 1%;
    width: 98%;
    height: calc(100% - 50px);
  }
}

/**查询区域*/
.query-form-body {
  padding: 8px 8px 8px 8px;
  width: 99%;
  background-color: #ffffff;
  .el-form-item {
    margin-bottom: 10px;
    font-weight: bold;
  }
}
//按钮区域
#buts {
  margin-top: 10px;
}
// 按钮栏样式
.but-list-body {
  padding: 5px 5px;
  width: 100%;
  border: 1px solid #ebeef5;
}
.query-top {
  overflow: hidden;
  position: relative;
  min-height: 100px;
  .left {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .right {
    position: absolute;
    top: 10px;
    right: 5px;
    text-align: center;
  }
  .buttom {
    margin-left: 10px;
  }
}
</style>
