<template>
  <div id="app">
    <router-view class="app-router-view" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { provide, onMounted } from 'vue';
import DevicePixelRatio from '@/utils/evicePixelRatio.js';
import { useStore } from 'vuex';
import { getUser } from '@/utils/auth';
export default {
  name: 'App',
  setup() {
    provide('echarts', echarts); //provide
    const bodyScale = () => {
        var devicewidth = document.documentElement.clientWidth, //获取当前分辨率下的可是区域宽度
          scale = devicewidth / 1920; // 分母——设计稿的尺寸

        document.body.style.zoom = scale; //放大缩小相应倍数
      },
      store = useStore();
    // eslint-disable-next-line no-undef

    onMounted(() => {
      new DevicePixelRatio().init();
      bodyScale();
      //设置用户基本信息
      const userInfo = getUser();

      console.log('APP用户信息', userInfo);
      if (userInfo) {
        store.dispatch('user/updateUserInfo', userInfo);
        store.dispatch('SetSysDictList', userInfo.dictionaryList);
      }
    });
  }
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.app-router-view {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}
</style>
