/** 公共表 */
import Model from 'js-model';
import options from '@/utils/options';
export class Entity extends Model {
  parse(data) {
    return super.parse(data);
  }

  dispose(data, param) {
    const newData = {};

    for (const key in data) {
      if (data[key] !== []) {
        newData[key] = data[key];
      }
    }
    return super.dispose(newData, param);
  }
}
const baseEntity = new Entity({
  //  id
  id: String,
  // 创建时间
  createTime: {
    type: Date,
    format: options.dateFormat.dateAndTime,
    dispose(data) {
      return data.createTime;
    }
  },
  // 更新时间
  updateTime: {
    type: Date,
    format: options.dateFormat.dateAndTime,
    dispose(data) {
      return data.updateTime;
    }
  }
});

export default baseEntity;
