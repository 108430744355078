/* eslint-disable one-var */
export const TokenKey = 'Authorization'; //登录
export const userKey = 'user'; //用户信息
export const adminKey = 'admin'; //系统管理员
export const orgTreeKey = 'orgTree'; //机构树状列表
export const menuKey = 'menu'; //用户菜单按钮权限树状列表

import ase from '@/utils/ase';

export function getToken () {
  const token = localStorage.getItem(ase.encrypt(TokenKey));

  if (token) {
    return ase.decrypt(token);
  }
  return token;

}

export function setToken (token) {
  return localStorage.setItem(ase.encrypt(TokenKey), ase.encrypt(token));
}

export function removeToken () {
  return localStorage.removeItem(ase.encrypt(TokenKey));
}

/**用户 */
export function setUser (user) {
  return localStorage.setItem(ase.encrypt(userKey), ase.encrypt(JSON.stringify(user)));
}
export function getUser () {
  let user = localStorage.getItem(ase.encrypt(userKey));

  if (user) {
    return JSON.parse(ase.decrypt(user));
  }
  return user;
}

export function removeUser () {
  return localStorage.removeItem(ase.encrypt(userKey));
}