const getters = {
  loginUserInfo: state => state.user.userInfo,
  token: state => state.user.userInfo ? state.user.userInfo.token : null,
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  userType: state => state.user.userInfo ? state.user.userInfo.type : null,
  menuList: state => state.user.userInfo ? state.user.userInfo.menuList : null,
  adminList: state => state.user.userInfo ? state.user.userInfo.adminList : null,
  organizationList: state => state.user.userInfo ? state.user.userInfo.organizationList : null,
  themeColor: state => state.permission.themeColor,
  collapse: state => state.permission.collapse,
  menuUrl: state => state.permission.menuUrl
};

export default getters;
