<!--表格显示图片或根据内容生成二维码组件-->
<template>
  <!--preview-src-list:开启图片预览功能-->
  <el-image
    preview-teleported
    hide-on-click-modal
    close-on-press-escape
    fit="cover"
    :src="imageUrl"
    :preview-src-list="previewList"
    :style="{ width: props.width, height: props.height, borderRadius: '50%' }"
  >
    <!--出错时显示-->
    <template #error>
      <img src="@/assets/headPortrait.jpg" class="headPortraitIimg" />
    </template>
  </el-image>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import QRCode from 'qrcode';

const props = defineProps({
  type: {
    //类型 image:图片(图片数组，取第一个) imageHead: 用户头像 QRCode: 二维码
    type: String,
    default: 'image'
  },
  width: {
    //图片宽度
    type: String,
    default: '35px'
  },
  height: {
    //图片高度
    type: String,
    default: '35px'
  },
  path: {
    //生成二维码的内容/图片地址
    type: Object,
    default: null
  }
});

//自定义变量
let imageUrl = ref(null), //显示的二维码地址
  previewList = ref([]); //图片预览数组

//生成二维码
// eslint-disable-next-line one-var
const makeQRCode = async (data) => {
  imageUrl.value = await QRCode.toDataURL(data);
  previewList.value.push(imageUrl.value);
};

onMounted(() => {
  if (props.path) {
    const type = typeof props.path[0];

    if ('QRCode' === props.type) {
      makeQRCode(props.path);
    } else if ('imageHead' === props.type) {
      imageUrl.value = props.path;
      previewList.value.push(imageUrl.value);
    } else if ('image' === props.type && type === 'object') {
      imageUrl.value = props.path[0].fullUrl;
      previewList.value.push(imageUrl.value);
    }
  } else {
    //默认显示
    imageUrl.value = '/assets/headPortrait.jpg';
    previewList.value.push(imageUrl.value);
  }
});

watch(
  () => props.path,
  (newValue) => {
    if (newValue) {
      if ('QRCode' === props.type) {
        makeQRCode(newValue);
      } else if ('imageHead' === props.type) {
        imageUrl.value = newValue;
        previewList.value.push(imageUrl.value);
      } else if ('image' === props.type && newValue[0] && newValue[0].fullUrl) {
        imageUrl.value = newValue[0].fullUrl;
        previewList.value.push(imageUrl.value);
      }
    }
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>

<style lang="scss" scoped>
.headPortraitIimg {
  width: 35px;
  height: 35px;
  border-radius: 21px;
}
</style>
