// 数据字典api
import { dictionaryQueryList } from '@/api/sys/dictionary';
const sysDict = {
  state: {
    // 数据字典列表
    list: [],
    // 等待请求的dictionaryCategoryCode
    dictionaryCategoryCodeList: []
  },
  mutations: {
    PUSH_SYS_DICT_PENGING_REQUEST_CODES: (state, dictionaryCategoryCode) => {
      state.dictionaryCategoryCodeList.push(dictionaryCategoryCode);
    },
    //   添加数据字典的值
    PUSH_SYS_DICT_LIST: (state, data) => {
      for (let i = 0; i < data.list.length; i++) {
        const codeData = state.list.find(r => data.list[i].dictionaryCategoryCode === r.dictionaryCategoryCode && data.list[i].value === r.value);

        if (undefined === codeData) {
          state.list.push(data.list[i]);
        }
      }
      state.dictionaryCategoryCodeList = state.dictionaryCategoryCodeList.filter(r => r !== data.dictionaryCategoryCode);
    }
  },
  getters: {
    //   获取数据字典值
    getSysDictList: (state) => (dictionaryCategoryCode) => {
      return state.list.filter(r => r.dictionaryCategoryCode === dictionaryCategoryCode);
    }
  },
  actions: {
    // 获取字典值
    GetSysDictList ({ commit, state }, dictionaryCategoryCode) {
      const codeData = state.list.find(r => dictionaryCategoryCode === r.dictionaryCategoryCode),
        dictionaryCategoryCodeList = state.dictionaryCategoryCodeList;

      if (Boolean(dictionaryCategoryCode) && undefined === codeData && undefined === dictionaryCategoryCodeList.find(r => r === dictionaryCategoryCode)) {
        commit('PUSH_SYS_DICT_PENGING_REQUEST_CODES', dictionaryCategoryCode);
        return new Promise((resolve, reject) => {
          const params = {
            orderBy: 'sort_number',
            desc: false,
            queryEntity: { dictionaryCategoryCode: dictionaryCategoryCode }
          };

          dictionaryQueryList(params).then(res => {
            const { success, data } = res;

            if (success && Boolean(data)) {
              commit('PUSH_SYS_DICT_LIST', { dictionaryCategoryCode: dictionaryCategoryCode, list: data });
              resolve(res);
            } else {
              reject(res);
            }
          });
        });
      }
    },
    // 设置字典值(仅首次登录时使用)
    SetSysDictList ({ commit, state }, dictionaryList) {
      state.dictionaryCategoryCodeList = [];
      state.list = [];
      console.log('dictionaryList', dictionaryList);
      return new Promise((resolve, reject) => {
        if (dictionaryList) {
          dictionaryList.forEach(element => {
            commit('PUSH_SYS_DICT_PENGING_REQUEST_CODES', element.dictionaryCategoryCode);
            commit('PUSH_SYS_DICT_LIST', element);
          });
        }
        resolve();
      });
    }
  }
};

export default sysDict;
