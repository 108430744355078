<!-- 数据字典表格 -->
<template>
    <el-table-column :label="label" :align="align" :min-width="minWidth">
      <template #default="scope">
        <template v-if="!!scope.row && !!scope.row[prop]">
          <el-tag
            v-if="props.type === 'tag'"
            size="small"
            :type="scope.row[prop].remark"
          >
            {{ scope.row[prop].name }}
          </el-tag>
          <span v-else :style="{ color: scope.row[prop].remark }">
            {{ scope.row[prop].name }}
          </span>
        </template>
      </template>
    </el-table-column>
  </template>

<script setup>
// eslint-disable-next-line newline-after-var
const props = defineProps({
  type: {
    //展示类型(tag text)
    type: String,
    default: 'tag'
  },
  label: {
    //标题
    type: String,
    default: ''
  },
  //显示位置
  align: {
    type: String,
    default: 'center'
  },
  // 最小宽度
  minWidth: {
    type: String,
    default: '100'
  }, //传入的值
  prop: {
    type: String,
    default: ''
  }
});
</script>
  <style lang="scss" scoped></style>
