<!-- 表格管理员回显 -->
<template>
  <el-table-column
    v-if="uerType === '0'"
    :label="label"
    :align="align"
    :min-width="minWidth"
  >
    <template #default="scope">
      {{ scope.row.adminUserName }}
    </template>
  </el-table-column>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';

// eslint-disable-next-line newline-after-var
const store = useStore(),
  props = defineProps({
    //显示位置
    align: {
      type: String,
      default: 'center'
    },
    //最小宽度
    minWidth: {
      type: String,
      default: '120'
    }
  }),
  uerType = ref(store.getters.userType); //当前登录用户类型
</script>
<style lang="scss" scoped></style>
