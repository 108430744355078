/* eslint-disable no-shadow */
/* eslint-disable indent */
/* eslint-disable one-var */
export const loadView = (view) => {
  return (resolve) => require([`@/views/${view}`], resolve);
};
/** 动态注册路由 */
function convertAsyncRouter (data = []) {
  if (data && data.length > 0) {
    data.forEach(menu => {
      menu.component = import('@/views/' + menu.url);
      menu.meta = { title: menu.name, icon: menu.icon, permission: menu.button };
      if (menu.hasChildren) {
        menu.children = convertAsyncRouter(menu.children);
      }
    });
  }
  return data;
}

const state = {
  collapse: false,
  themeColor: '#37474F',//主题背景色(头部+左侧菜单栏)
  menuUrl: '',
  resources: []
},
  mutations = {
    // eslint-disable-next-line no-shadow
    SET_ROUTES: (state, routes) => {
      state.resources = routes;
    },
    //左侧菜单是否展开
    // eslint-disable-next-line no-shadow
    SET_COLLAPSE: (state, collapse) => {
      state.collapse = collapse;
    },
    // 设置主题色
    SET_THEME_COLOR: (state, themeColor) => {
      state.themeColor = themeColor;
    },
    SET_MENUURL: (state, menuUrl) => {
      state.menuUrl = menuUrl;
    }
  },

  actions = {
    generateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const accessedRoutes = convertAsyncRouter(data);

        commit('SET_ROUTES', accessedRoutes);
        resolve(accessedRoutes);
      });
    },
    setCollapse ({ commit }, collapse) {
      commit('SET_COLLAPSE', collapse);
    },
    setThemeColor ({ commit }, themeColor) {
      commit('SET_THEME_COLOR', themeColor);
    },
    setMenuUrl ({ commit }, menuUrl) {
      commit('SET_MENUURL', menuUrl);
    }
  };

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
