/* eslint-disable indent */
/* eslint-disable no-shadow */
import { mobileRegister, webWeChartRegister } from '@/api/sys/register';
import { mobilePasswdLogin, mobileSmsLogin, webScanCodeLogin, logout } from '@/api/sys/login';
import { setUser, removeUser } from '@/utils/auth';
import { ElMessage, ElLoading } from 'element-plus';
import router from '@/router';
// import ase from '@/utils/ase.js'
import { encrypt } from '@/utils/jsencrypt';
import store from '@/store';
const state = {
  userInfo: null
},
  mutations = {
    SET_USER_INFO: (state, info) => {
      state.userInfo = info;
    }
  },
  actions = {
    //更新用户信息
    updateUserInfo ({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    /** 账号密码注册 */
    mobileRegisterHandle ({ commit }, userInfo) {
      const loading = ElLoading.service({
        lock: true,
        text: '用户注册中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }),
        { password, mobile } = userInfo,
        formData = { ...userInfo };

      //电话和密码加密
      formData.mobile = encrypt(mobile.trim());
      formData.password = encrypt(password.trim());
      return new Promise((resolve, reject) => {
        mobileRegister(formData).then(res => {
          loading.close();
          if (res.success) {
            router.push(`/login`);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    /** 网页应用-微信用户账注册 */
    webWeChartRegisterHandle ({ commit }, userInfo) {
      const loading = ElLoading.service({
        lock: true,
        text: '用户注册中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }),
        { webId, mobile } = userInfo,
        formData = { ...userInfo };

      //电话和微信openid加密
      formData.mobile = encrypt(mobile.trim());
      formData.webId = encrypt(webId.trim());
      return new Promise((resolve, reject) => {
        webWeChartRegister(formData).then(res => {
          loading.close();
          if (res.success) {
            router.push(`/login`);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    /** 手机号-密码 登录 */
    mobilePasswdLoginHandle ({ commit }, userInfo) {
      const loading = ElLoading.service({
        lock: true,
        text: '用户登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }),
        { mobile, password } = userInfo,
        formData = { ...userInfo };

      commit('SET_USER_INFO', {});
      formData.mobile = encrypt(mobile.trim());
      formData.password = encrypt(password.trim());
      return new Promise((resolve, reject) => {
        mobilePasswdLogin(formData).then(res => {
          if (res.success) {
            loading.close();
            console.log('登录用户信息', res.data);
            commit('SET_USER_INFO', res.data);
            setUser(res.data);
            store.dispatch('SetSysDictList', res.data.dictionaryList);
            router.push({ path: '/project/sz' });
            resolve(res);
          } else {
            loading.close();
            reject(res);
          }
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    /** 手机号-短信验证码 登录 */
    mobileSmsLoginHandle ({ commit }, userInfo) {
      const loading = ElLoading.service({
        lock: true,
        text: '用户登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }),
        { mobile, code } = userInfo,
        formData = { ...userInfo };

      commit('SET_USER_INFO', {});
      formData.mobile = encrypt(mobile.trim());
      formData.code = code.trim();
      return new Promise((resolve, reject) => {
        mobileSmsLogin(formData).then(res => {
          if (res.success) {
            loading.close();
            console.log('登录用户信息', res.data);
            commit('SET_USER_INFO', res.data);
            setUser(res.data);
            store.dispatch('SetSysDictList', res.data.dictionaryList);
            router.push({ path: '/sz/project' });
            resolve(res);
          } else {
            loading.close();
            reject(res);
          }
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    /** 网页应用-微信扫码授权登录 */
    webScanCodeLoginHandle ({ commit }, userInfo) {
      const loading = ElLoading.service({
        lock: true,
        text: '用户登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }),
        { code } = userInfo,
        formData = { ...userInfo };

      commit('SET_USER_INFO', {});
      formData.code = encrypt(code.trim());
      return new Promise((resolve, reject) => {
        webScanCodeLogin(formData).then(res => {
          console.log('登录用户信息', res);
          loading.close();
          if (res.success) {
            console.log('登录用户信息', res.data);
            commit('SET_USER_INFO', res.data);
            setUser(res.data);
            store.dispatch('SetSysDictList', res.data.dictionaryList);
            router.push({ path: '/project/sz' });
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },


    /** 用户登出 */
    logout ({ commit, state }) {
      const loading = ElLoading.service({
        lock: true,
        text: '正在退出系统,请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      return new Promise((resolve, reject) => {
        logout().then(() => {
          ElMessage.success('退出成功');
          removeUser();
          commit('SET_USER_INFO', {});
          router.push(`/login`);
          location.reload();
          loading.close();
          resolve();
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        removeUser();
        commit('SET_USER_INFO', {});
        router.push(`/login`);
        resolve();
      });
    }
  };

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

