import request from '@/utils/request';

/** 手机号-密码 登录 */
export function mobilePasswdLogin (data) {
  return request({
    url: `/api/auth-server/login/mobilePasswdLogin`,
    method: 'post',
    data: data
  });
}

/** 手机号-短信验证码 登录 */
export function mobileSmsLogin (data) {
  return request({
    url: `/api/auth-server/login/mobileSmsLogin`,
    method: 'post',
    data: data
  });
}

/** 网页应用-微信扫码-授权登录 */
export function webScanCodeLogin (data) {
  return request({
    url: `/api/auth-server/login/webScanCodeLogin`,
    method: 'get',
    params: data
  });
}


/** 用户登出 */
export function logout () {
  return request({
    url: `/api/auth-server/login/loginOut`,
    method: 'get'
  });
}


// 根据原密码修改密码
export function updatepassword (data) {
  return request({
    url: `/api/auth-server/sys/user/updatePassWord`,
    method: 'post',
    data: data
  });
}


/** 登录项目系统-获取登录用户信息 */
export function loginChildren (data) {
  return request({
    url: `/api/auth-server/login/loginChildren`,
    method: 'get',
    params: data
  });
}